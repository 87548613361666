import '../scss/style.scss';

window.onload = function(){

    //DOCUMENT LOAD -> REMOVE PRELOADER
    document.querySelector('.preloader').remove();
    document.body.classList.remove('body-loading');

    // OWL
    $('.owl-carousel.owl-banner').owlCarousel({
        loop:true,
        dots: false,
        margin:10,
        nav:true,
        autoplay:true,
        autoplayTimeout: 850000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    });
    $('.owl-carousel.owl-info').owlCarousel({
        loop: true,
        dots: false,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 850000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    });
    $('.owl-carousel.owl-product').owlCarousel({
        items: 3,
        loop: false,
        dots:false,
        rewind: true,
        autoplay: false,
        margin: 0,
        nav: true,
        responsive:{
            0:{
                items:1
            },
            767:{
                items:1
            },
            768: {
                items:3
            }
        }
    });
    $('.owl-product-cross-sale').owlCarousel({
        loop: true,
        dots: false,
        margin: 10,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:4
            }
        }
    });$('.owl-carousel.owl-info').owlCarousel({
        loop: true,
        dots: false,
        margin: 10,
        nav: true,
        autoplay: true,
        autoplayTimeout: 850000,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    })


    //BANNER BACKGROUND
    window.addEventListener('resize', (e) => {
        if( document.querySelector('.banner-group') ){
            const backgroundImg = document.querySelector('.banner-group').dataset.background;
            if(window.innerWidth < 556 ){
                document.querySelector('.banner-group').style.backgroundImage = '';
                document.querySelector('.banner-group__cta-left').style.backgroundImage = `url(${backgroundImg})`;
            } else {
                document.querySelector('.banner-group').style.backgroundImage = `url(${backgroundImg})`;
                document.querySelector('.banner-group__cta-left').style.backgroundImage = '';
            }
        }

    });
    if( document.querySelector('.banner-group') ){
        const backgroundImg = document.querySelector('.banner-group').dataset.background;
        if(window.innerWidth < 556 ){
            document.querySelector('.banner-group').style.backgroundImage = '';
            document.querySelector('.banner-group__cta-left').style.backgroundImage = `url(${backgroundImg})`;
        } else {
            document.querySelector('.banner-group').style.backgroundImage = `url(${backgroundImg})`;
            document.querySelector('.banner-group__cta-left').style.backgroundImage = '';
        }
    }




    //TOP MENU document SCROLL
    const preheadElementHeight = parseInt(getComputedStyle( document.querySelector('.header-group')).height);
    document.addEventListener('scroll', (e) => {
        if( window.scrollY > preheadElementHeight ){
            if( window.innerWidth < 767 ) {
                document.querySelectorAll('.no-display-onscroll').forEach((i)=>{
                    i.style.display = 'none';
                })
            }
        }
        if (window.scrollY === 0) {
            if(window.innerWidth < 767) {
                document.querySelectorAll('.no-display-onscroll').forEach((i)=>{
                    i.style.display = 'block';
                })
            } else {
                document.querySelectorAll('.no-display-onscroll')[0].style.display = 'block';
            }
        }
    })


    //SCROLL ANIMATION
    function animateFrom(elem, direction) {
        direction = direction | 1;

        var x = 0,
            y = direction * 100;
        if(elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if(elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });
    }

    function hide(elem) {
        gsap.set(elem, {autoAlpha: 0});
    }

    //window.addEventListener("load", function() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
        hide(elem); // assure that the element is hidden when scrolled into view

        setTimeout(()=>{

            ScrollTrigger.create({
                trigger: elem,
                onEnter: function() { animateFrom(elem) },
                onEnterBack: function() { animateFrom(elem, -1) },
                onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
            });

        },250)

    });


    //    go-top
    const go_to_top_btn = {

        btn_id: 'go-top',
        btn_inner_html: `<div>
                        <i class="fas fa-chevron-up"></i>
                     </div>`,

        init() {
            this._create_btn();
            if( document.body.scrollHeight > window.innerHeight ) {
                document.querySelector('#go-top').addEventListener('click',function(){
                    $('html, body').animate({
                        scrollTop: 0
                    }, 900 );
                })

                document.addEventListener( 'scroll', function(){
                    if( window.pageYOffset < window.innerHeight / 2 ) {
                        document.querySelector('#go-top').style.opacity = 0;
                    } else {
                        document.querySelector('#go-top').style.opacity = 1;
                    }
                })
            }

        },


        _create_btn(){
            const footer = document.querySelector('.footer');
            if( footer ){
                let btn = document.createElement('div');
                btn.setAttribute('id', this.btn_id );
                btn.innerHTML = this.btn_inner_html;
                document.body.insertBefore(btn,footer);
            }
        }


    }
    go_to_top_btn.init();



    //product-gallery
    if( document.querySelector('.owl-product__item') ) {

        document.querySelectorAll('.owl-product__item').forEach((i)=>{
            i.addEventListener('click', (e) => {
                console.log('d')
                const pic = e.currentTarget.children[0].getAttribute('src')
                document.querySelector('.product-image-group__gallery_display-block').style.backgroundImage = `url(${pic})`;
            })
        })
    }

    //product-tabs
    if(document.querySelector('.product-tabs-group__tabs_item')){
        document.querySelectorAll('.product-tabs-group__tabs_item a').forEach((i) => {
            i.addEventListener('click',(e) => {
                e.preventDefault();
                const id = e.currentTarget.getAttribute('href');
                document.querySelectorAll('.product-tabs-group__content').forEach((j) => {
                    j.style.display = 'none';
                })
                document.querySelectorAll('.product-tabs-group__tabs_item ').forEach((j) => {
                    j.classList.remove('active');
                })

                e.currentTarget.parentElement.classList.add('active');
                document.querySelector(id).style.display = 'block';
            })
        })


    }

}